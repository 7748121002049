import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import DigitalAgency from "./pages/DigitalAgency";
import Impressum from "./pages/Impressum";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://analytics.embres.de/js/container_jCaXWkhT.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={DigitalAgency}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/impressum"}`}
            exact
            component={Impressum}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
